<template>
    <div align="left" justify="center" class="pa-3">
        <Loading :active="loadingInicial" :is-full-page="true" />
        <v-card align="left" justify="left">
            <v-card-text class="pa-3 font-weight-light white black--text">
                <v-icon class="primary--text lighten-2 pa-2">
                    mdi-chart-box </v-icon
                >Relatórios > Mensageria > Campanhas WhatsApp
            </v-card-text>
        </v-card>
        <v-card class="">
            <v-row align="center" class="mx-3 mt-3 py-4">
                <v-col lg="12" class="px-1 py-3">
                    <v-card-text class="pa-0"
                        ><v-icon class="mx-2">mdi-information-outline</v-icon
                        >Utilize os campos abaixo para aplicar filtros na
                        pesquisa</v-card-text
                    >
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-text-field
                        clearable
                        solo
                        small
                        prepend-icon="mdi-chevron-right"
                        label="Id da Campanha"
                        placeholder="Id da Campanha"
                        elevation="0"
                        hide-details="auto"
                        v-model="filtros.id"
                        @keydown.enter="filtrar()"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-select
                        clearable
                        solo
                        small
                        :items="status_campanha_items"
                        item-text="label"
                        item-value="value"
                        prepend-icon="mdi-chevron-right"
                        label="Status da Campanha"
                        placeholder="Status da Campanha"
                        elevation="0"
                        hide-details="auto"
                        v-model="filtros.is_active"
                        @keydown.enter="filtrar()"
                    ></v-select>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-select
                        clearable
                        solo
                        small
                        :items="tipo_campanha_items"
                        item-text="label"
                        item-value="value"
                        prepend-icon="mdi-chevron-right"
                        label="Tipo da Campanha"
                        placeholder="Tipo da Campanha"
                        elevation="0"
                        hide-details="auto"
                        v-model="filtros.campaign_type"
                        @keydown.enter="filtrar()"
                    ></v-select>
                </v-col>
                <v-col
                    cols="4"
                    xs="2"
                    sm="2"
                    md="3"
                    lg="3"
                    class="d-flex flex-row"
                >
                    <v-autocomplete
                        solo
                        multiple
                        small
                        v-model="filtros.title_status"
                        :items="listaSituacoes"
                        label="Status do Título"
                        placeholder="Status do Título"
                        item-text="nm_status"
                        item-value="cd_status"
                        prepend-icon="mdi-chevron-right"
                        clearable
                        hide-details="auto"
                        :disabled="desabilitarStatusTitulo()"
                        data-cy="statusTitulo"
                    />
                    <v-tooltip top v-if="desabilitarStatusTitulo()">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                x-small
                                class="ma-auto ml-2"
                                elevation="0"
                            >
                                <v-icon color="grey" v-bind="attrs" v-on="on">
                                    mdi-information-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span
                            >Selecione Tipo da Campanha "Títulos" para habilitar
                            busca por Status</span
                        >
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row align="center" class="mx-3 mt-3 py-4">
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-menu
                        solo
                        v-model="dataEnvioInicial"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                clearable
                                @click:clear="
                                    limparFiltroData('send_date_from')
                                "
                                solo
                                hide-details="auto"
                                :value="dataEnvioInicialFormatada"
                                label="Data Inicial"
                                prepend-icon="mdi-calendar-cursor"
                                persistent-hint
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filtros.send_date_from"
                            selected-items-text="Datas Selecionadas"
                            color="primary"
                            locale="pt-br"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-menu
                        solo
                        v-model="dataEnvioFinal"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                clearable
                                @click:clear="limparFiltroData('send_date_to')"
                                solo
                                hide-details="auto"
                                :value="dataEnvioFinalFormatada"
                                label="Data Final"
                                prepend-icon="mdi-calendar-cursor"
                                persistent-hint
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="filtros.send_date_to"
                            selected-items-text="Datas Selecionadas"
                            color="primary"
                            locale="pt-br"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row align="center" justify="end" class="mx-3 mt-3 py-4">
                <v-btn
                    class="mr-3"
                    color="primary"
                    :disabled="false"
                    :loading="loadingFilter"
                    @click="filtrar()"
                >
                    Buscar Resultados
                    <v-icon class="ml-2">mdi-magnify</v-icon>
                </v-btn>
            </v-row>
        </v-card>

        <v-card class="mt-6">
            <v-data-table
                :headers="headers"
                :items="resultadosCampanhasWhatsapp"
                :footer-props="{
                    itemsPerPageOptions: [10, 50, 100, 500, -1]
                }"
                :loading="loadingTable"
                loading-text="Pesquisando Campanhas..."
                no-data-text="Nenhum resultado encontrado... Faça uma pesquisa"
                no-results-text="Nenhum resultado encontrado..."
                class="elevation-1 pa-3"
            >
                <template v-slot:[`header.send_date`]="{ header }">
                    <span>{{ header.text }}</span>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon color="grey-lighten-1">
                                    mdi-help-circle-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span
                            >Data prevista para o início dos envios, podendo se
                            extender dependendo da capacidade de envio dos
                            remetentes WhatsApp disponíveis</span
                        >
                    </v-tooltip>
                </template>
                <template v-slot:[`header.enviados_total`]="{ header }">
                    <span>{{ header.text }}</span>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon color="grey-lighten-1">
                                    mdi-help-circle-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span
                            >Total de envios realizados pela campanha até o
                            momento</span
                        >
                    </v-tooltip>
                </template>
                <template v-slot:[`header.enviados_parcial`]="{ header }">
                    <span>{{ header.text }}</span>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon color="grey-lighten-1">
                                    mdi-help-circle-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span
                            >Envios realizados dentro do intervalo de data
                            utilizado no filtro</span
                        >
                    </v-tooltip>
                </template>
                <template v-slot:[`item.id`]="{ item }">
                    <span>
                        {{ item.id }}
                    </span>
                </template>
                <template v-slot:[`item.title`]="{ item }">
                    <span>
                        {{ item.title }}
                    </span>
                </template>
                <template v-slot:[`item.send_date`]="{ item }">
                    <span>
                        {{ formatarDataHoraMoment(item.send_date) }}
                    </span>
                </template>
                <template v-slot:[`item.created_by_title`]="{ item }">
                    <span v-if="item.created_by_title == true">{{
                        'Título'
                    }}</span>
                    <span v-else>{{ 'Contatos' }}</span>
                </template>
                <template v-slot:[`item.enviados_total`]="{ item }">
                    <span v-if="item.created_by_title == true">{{
                        item.sent_title_submissions
                    }}</span>
                    <span v-else>{{ item.sent_contacts }}</span>
                </template>
                <template v-slot:[`item.enviados_parcial`]="{ item }">
                    {{ item.submissions }}
                </template>
                <template v-slot:[`item.contatos`]="{ item }">
                    <span v-if="item.created_by_title == true">{{
                        item.all_title_submissions
                    }}</span>
                    <span v-else>{{ item.all_contacts }}</span>
                </template>
                <template v-slot:[`item.message`]="{ item }">
                    <span v-if="item.message.length < 8">{{
                        item.message
                    }}</span>
                    <span v-if="item.message.length > 8">{{
                        item.message.substring(0, 60) + '..'
                    }}</span>
                </template>
                <template v-slot:[`item.is_active`]="{ item }">
                    <v-switch
                        :color="item.is_active != '' ? 'green' : 'red'"
                        readonly
                        v-model="item.is_active"
                        inset
                        :style="{
                            color: item.is_active != '' ? 'green' : 'red'
                        }"
                    >
                    </v-switch>
                </template>
                <!-- TODO botão de acesso aos detalhes da Campanha Relatório -->
                <!-- <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                        elevation="1"
                        width="100%"
                        small
                        :loading="loadingFiltros"
                        @click="exibirDetalhe(item)"
                        class="mr-2 grey white--text"
                    >
                        <v-icon
                            medium
                            class="mr-2"
                            @click="exibirDetalhe(item)"
                            :style="{ color: 'white' }"
                            >edit</v-icon
                        >Editar
                    </v-btn>
                </template> -->
            </v-data-table>
        </v-card>
        <v-card class="mt-3">
            <v-row class="my-4 ml-4">
                <v-col lg="12" class="text-end">
                    <v-btn
                        class="mx-6"
                        color="primary"
                        disabled
                        :loading="loadingExport"
                        @click="exportar()"
                    >
                        Exportar resultados
                        <v-icon small class="ma-2">cloud_download</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <Snackbar
            v-if="snackbarDialog"
            :mostrarSnackbar="snackbarDialog"
            :corSnackbar="snackbarColor"
            :mensagemSnackbar="mensagemRetornoSnackbar"
            @fecharSnackbar="snackbarDialog = false"
        />
    </div>
</template>

<script>
import Vue from 'vue';
// import TituloService from '@/services/TituloService';
import WhatsappService from '@/services/WhatsappService';
import { getOnlyNumbers, formatarDataHoraMoment } from '@/helpers/Utilitarios';
import lodash from 'lodash';
import moment from 'moment';
import { listarSituacoesTitulo } from '@/helpers/Utilitarios';
import Loading from 'vue-loading-overlay';

export default {
    name: 'RelatorioCampanhasWhatsapp',
    components: { Loading },
    data() {
        return {
            resultadosCampanhasWhatsapp: [],
            status_campanha_items: [
                { value: '', label: 'Todos' },
                { value: 'true', label: 'Ativo' },
                { value: 'false', label: 'Inativo' }
            ],
            tipo_campanha_items: [
                { value: '', label: 'Todos' },
                { value: 'CONTATOS', label: 'Contatos' },
                { value: 'TITULOS', label: 'Títulos' }
            ],
            dataEnvioInicial: null,
            dataEnvioFinal: null,
            headers: [
                {
                    text: 'Id',
                    sortable: false,
                    value: 'id'
                },
                {
                    text: 'Título',
                    sortable: false,
                    value: 'title'
                },
                {
                    text: 'Mensagem',
                    sortable: false,
                    value: 'message'
                },
                {
                    text: 'Data de Envio',
                    sortable: false,
                    value: 'send_date'
                },
                {
                    text: 'Tipo de Campanha',
                    sortable: false,
                    value: 'created_by_title'
                },
                {
                    text: 'Números Vinculados',
                    sortable: false,
                    value: 'contatos'
                },
                {
                    text: 'Envios Totais',
                    sortable: false,
                    value: 'enviados_total'
                },
                {
                    text: 'Envios Parciais',
                    sortable: false,
                    value: 'enviados_parcial'
                },
                {
                    text: 'Ativo/Inativo',
                    sortable: false,
                    value: 'is_active'
                },
                { text: ' ', sortable: false, value: 'action' }
            ],
            options: {
                page: 1,
                itemsPerPage: 10
            },
            loadingInicial: false,
            snackbarColor: null,
            snackbarDialog: false,
            snackbarIcon: null,
            mensagemRetornoSnackbar: null,
            loadingFilter: false,
            loadingExport: false,
            loadingTable: false,
            loadingFiltros: false,
            emailReport: '',
            modalReceberRelatorioEmail: false,
            filtros: {
                id: null,
                is_active: null,
                campaign_type: null,
                title_status: null,
                send_date_from: null,
                send_date_to: null
            },
            mostraConfirmacaoEmail: false,
            primaryColorFont: '#757575'
        };
    },
    watch: {
        'filtros.campaign_type': {
            deep: true,
            handler() {
                if (
                    !this.filtros.campaign_type ||
                    this.filtros.campaign_type !== 'TITULOS'
                ) {
                    this.filtros.title_status = null;
                }
            }
        }
    },
    computed: {
        listaSituacoes() {
            return this.$store.getters.situacoesTitulo;
        },
        dataEnvioInicialFormatada() {
            if (this.filtros.send_date_from) {
                return moment(this.filtros.send_date_from).format('DD/MM/YYYY');
            }
            return null;
        },
        dataEnvioFinalFormatada() {
            if (this.filtros.send_date_to) {
                return moment(this.filtros.send_date_to).format('DD/MM/YYYY');
            }
            return null;
        }
    },
    async mounted() {
        this.loadingInicial = true;
        await listarSituacoesTitulo(Vue.http, this.$store);
        this.loadingInicial = false;

        if (this.$store.getters.isPartner == 1) {
            this.primaryColorFont = '#757575';
        } else {
            this.primaryColorFont = this.$vuetify.theme.themes.light.primary;
        }
    },
    methods: {
        getOnlyNumbers,
        formatarDataHoraMoment,
        desabilitarStatusTitulo() {
            if (
                this.filtros.campaign_type &&
                this.filtros.campaign_type === 'TITULOS'
            ) {
                return false;
            }
            return true;
        },
        limparFiltroData(atributoData) {
            this.filtros[atributoData] = null;
        },
        mostrarSnackbar(icone, cor, mensagem) {
            this.snackbarDialog = true;
            this.snackbarIcon = icone;
            this.snackbarColor = cor;
            this.mensagemRetornoSnackbar = mensagem;
        },
        filtrar() {
            const whatsappService = new WhatsappService(Vue.http, this.$store);
            const itemsPerPage = this.options.itemsPerPage;
            const page = this.options.page;
            const filtro = lodash.cloneDeep(this.filtros);
            if (filtro.title_status?.length > 0) {
                filtro.title_status = filtro.title_status.join(',');
            }

            this.loadingFilter = true;
            this.loadingTable = true;

            whatsappService
                .relatorioCampanhas(filtro, itemsPerPage, page)
                .then(
                    response => {
                        if (response.status == 200) {
                            this.resultadosCampanhasWhatsapp =
                                response?.body?.results;

                            const successMsg =
                                this.resultadosCampanhasWhatsapp?.length > 0
                                    ? 'Busca efetuada com sucesso!'
                                    : 'Filtro buscado não retornou resultados.';
                            const successColor =
                                this.resultadosCampanhasWhatsapp?.length > 0
                                    ? 'success'
                                    : null;
                            this.mostrarSnackbar(
                                'mdi-text-box-check-outline',
                                successColor,
                                successMsg
                            );
                        }
                    },
                    error => {
                        if (error.status == 400) {
                            const errorMsg = error?.body?.msg
                                ? error?.body?.msg
                                : 'Erro ao executar o Filtro.';
                            this.mostrarSnackbar(
                                'mdi-close',
                                'error',
                                errorMsg
                            );
                        }
                    }
                )
                .finally(() => {
                    this.loadingFilter = false;
                    this.loadingTable = false;
                });
        }
        // TODO exportar resultados
        // exportar() {
        //     this.modalReceberRelatorioEmail = true;
        // },
        // exportarRelatoriosMsgs() {
        //     // this.filtros.cd_status = this.cd_status_aplicado;
        //     this.filtros.cd_apresentante = this.$store.getters.cdApresentante;
        //     let tituloService = new TituloService(Vue.http, this.$store);
        //     this.loadingFiltros = true;
        //     var emails = [];
        //     emails[0] = this.emailReport;

        //     tituloService
        //         .exportarFinanceiroResumido(this.filtros, emails)
        //         .then(response => {
        //             console.log(response);
        //             this.modalReceberRelatorioEmail = false;
        //             this.mostraConfirmacaoEmail = true;
        //             this.emailReport = null;
        //             this.loadingFiltros = false;
        //         })
        //         .catch(() => {
        //             this.loadingFiltros = false;
        //             this.modalEmailXls = false;
        //             this.mostraConfirmacaoEmail = true;
        //             this.emailReport = null;
        //         });
        // }
    }
};
</script>
